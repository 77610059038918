// Header.js
import { useNavigate } from "react-router-dom";

import React from "react";

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className='bg-12161a '>
      <div className='flex justify-end'>
        <button
          type='button'
          onClick={() => navigate("/survey")}
          className='border p-2 px-4  m-4 custom-button-2'
        >
          TAKE THE TEST
        </button>
       <button
  className='border p-2 px-4 m-4 custom-button uppercase'
  onClick={() => window.location.href = "https://buynfts.exchange/sales/400/"}
>
  MINT
</button>
      </div>
      <div className='flex pt-64 items-center justify-center'>
        <img
          src='./400logo.png'
          alt='400'
          className='max-w-full max-h-full'
        />
      </div>
    </header>
  );
};

export default Header;
