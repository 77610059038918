// Header.js
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const Join = () => {
  const navigate = useNavigate();
  return (
    <section className='flex bg-12161a'>
      <div className='flex items-center justify-center'>
        <button className='border p-2 px-4 absolute top-0 right-0 m-4 custom-button uppercase'>
          connect
        </button>
        {/* <button
          type='button'
          onClick={() => navigate("/survey")}
          className='border absolute top-0 right-40 p-2 px-4  m-4 custom-button-2'
        >
          TAKE THE TEST
        </button> */}
        <img
          src='./400logo.png'
          alt='400'
          className='w-14 absolute m-4 top-0 left-0'
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className='pt-48 flex items-center justify-center px-48 px5 text-2xl font-bold'>
        Throughout this journey you have been tested repeatedly. Beginning with
        a free NFT airdrop which soared in value. Do you hold or do you sell? If
        you held you were then faced with a choice to buy $AVREVS token or wait
        and see what happens. And finally you were put through a psychological
        personality profile that further entrenched you in the enigma of the
        400.
        <br />
        <br />
        Meanwhile the 400 raised thousands of dollars in royalties, which were
        quickly donated to charity. These funds helped many people during this
        Christmas and New Year season.
        <br />
        <br />
        You faced constant pressure. Wen utility? Wen roadmap? Wen engagement?
        Some lost faith and sold their NFT. Some realized that the mystery was
        just too much and moved on. You waited patiently. In a few short days
        you will face your final test.
        <br />
        <br />
        You will have the choice to commit fully to the 400, or to take what
        value is left and move on. You will burn your NFT, you will spend your
        $AVREVS, and you will become one of the 400. Forever. While you will
        receive a new NFT that signifies your membership, it cannot be bought
        nor sold. It cannot be sent nor received. It is bound to your wallet
        forever. You will become a valuable member of one of the IIII Leagues of
        the 400.
      </div>
    </section>
  );
};

export default Join;
