// Header.js
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Survey = () => {
  // loading for sending email messages
  const [Loading, SetLoading] = useState(false);

  const navigate = useNavigate();

  const contactForm = useRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  function onSubmitButton(data) {
    // console.log(data);
    SetLoading(true);
    emailjs
      .sendForm(
        "service_wnfkz28", // service Id
        "template_z2n3tls", // template Id
        contactForm.current,
        "E5OuTXM7BD8J59zMa" // public key
      )
      .then(
        (result) => {
          SetLoading(false);
          toast.success("Thank you for your submission");
          reset();
        },
        (error) => {
          console.log(error.text);
          SetLoading(false);
        }
      );
  }

  return (
    <section className='bg-12161a'>
      <Toaster
        position='top-right'
        reverseOrder={false}
      />
      <div className='flex items-center justify-center'>
        <button
          className='border p-2 px-4 absolute top-0 right-0 m-4 custom-button'
          onClick={() => navigate("/join")}
        >
          CONNECT
        </button>
        <img
          src='./400logo.png'
          alt='400'
          className='w-14 absolute m-4 top-0 left-0'
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className='px-48 pt-48 px5 text-2xl font-bold'>
        All relationships of people to each other rest, as a matter of course,
        upon the precondition that they know something about each other. Within
        each social stratum the individual knows approximately what measure of
        culture he has to presuppose in each other individual. So each person
        must know the other with whom he has to do, in a rough and ready way, to
        the degree necessary in order that the needed intercourse may proceed.
        It is with these affirming thoughts we must gather information, to know
        each of you, and understand via a reciprocal presentation, the place in
        which you fit. Alone we can do very little, but together we can do so
        much.
      </div>
      <form
        ref={contactForm}
        onSubmit={handleSubmit(onSubmitButton)}
      >
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <label
                for='username-success'
                class={`block mb-2 text-sm font-medium ${
                  errors.username ? "text-red-600" : ""
                }`}
              >
                Username (DISCORD)
              </label>
              <input
                type='text'
                id='username-success'
                class='bg-12161a border text-black text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5'
                placeholder='Username'
                {...register("username", { required: true })}
              />
              {errors.username && (
                <p class='mt-2 text-sm text-red-600'>
                  <span class='font-medium'>Oops! </span>Username Required!
                </p>
              )}
            </div>
          </div>
          <div class='order-2 lg:order-2'>
            <div>
              <label
                for='username-error'
                class={`block mb-2 text-sm font-medium ${
                  errors.wallet ? "text-red-600" : ""
                }`}
              >
                Wallet Address (MUST CONTAIN 400 INITIATION DAY NFT)
              </label>
              <input
                type='text'
                id='username-error'
                class='bg-12161a border text-black text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5'
                placeholder='Bonnie Green'
                {...register("wallet", { required: true })}
              />
              {errors.wallet && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  <span class='font-medium'>Oops!</span> Wallet Address
                  Required!
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Q1 Q2 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q1 ? "text-red-600" : ""}`}>Q1:</h1>
              <h1 className={`mb-3 ${errors.q1 ? "text-red-600" : ""}`}>
                Which word would people use to describe you?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Loyal'
                  class='w-4 h-4 gold'
                  {...register("q1", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Loyal
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Special'
                  class='w-4 h-4 gold'
                  {...register("q1", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Special
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Happy'
                  class='w-4 h-4 gold'
                  {...register("q1", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Happy
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Brave'
                  class='w-4 h-4 gold'
                  {...register("q1", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Brave
                </label>
              </div>
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q2 ? "text-red-600" : ""}`}>Q2:</h1>
              <h1 className={`mb-3 ${errors.q2 ? "text-red-600" : ""}`}>
                How do you stay calm under pressure?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Take Breaks'
                  class='w-4 h-4 gold'
                  {...register("q2", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Take Breaks
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Ask For Help'
                  class='w-4 h-4 gold'
                  {...register("q2", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Ask For Help
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Review Choices'
                  class='w-4 h-4 gold'
                  {...register("q2", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Review Choices
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Breath Deeply'
                  class='w-4 h-4 gold'
                  {...register("q2", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Breath Deeply
                </label>
              </div>
              {/* {errors.q2 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q3 Q4 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q3 ? "text-red-600" : ""}`}>Q3:</h1>
              <h1 className={`mb-3 ${errors.q3 ? "text-red-600" : ""}`}>
                Which would you choose as your favorite vacation destination?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Beach'
                  class='w-4 h-4 gold'
                  {...register("q3", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Beach
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Mountains'
                  class='w-4 h-4 gold'
                  {...register("q3", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Mountains
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='City'
                  class='w-4 h-4 gold'
                  {...register("q3", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  City
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Camping'
                  class='w-4 h-4 gold'
                  {...register("q3", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Camping
                </label>
              </div>
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q4 ? "text-red-600" : ""}`}>Q4:</h1>
              <h1 className={`mb-3 ${errors.q4 ? "text-red-600" : ""}`}>
                When you face a problem, what is the first thing you do?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Ask For Help'
                  class='w-4 h-4 gold'
                  {...register("q4", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Ask For Help
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Delegate To Someone Else'
                  class='w-4 h-4 gold'
                  {...register("q4", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Delegate To Someone Else
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Research Solutions'
                  class='w-4 h-4 gold'
                  {...register("q4", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Research Solutions
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Take A Break'
                  class='w-4 h-4 gold'
                  {...register("q4", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Take A Break
                </label>
              </div>
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q5 Q6 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q5 ? "text-red-600" : ""}`}>Q5:</h1>
              <h1 className={`mb-3 ${errors.q5 ? "text-red-600" : ""}`}>
                Which of the following did you like the most as a child?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Hide And Seek'
                  class='w-4 h-4 gold'
                  {...register("q5", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Hide And Seek
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Sports'
                  class='w-4 h-4 gold'
                  {...register("q5", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Sports
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Board Games'
                  class='w-4 h-4 gold'
                  {...register("q5", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Board Games
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Puzzles'
                  class='w-4 h-4 gold'
                  {...register("q5", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Puzzles
                </label>
              </div>
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q6 ? "text-red-600" : ""}`}>Q6:</h1>
              <h1 className={`mb-3 ${errors.q6 ? "text-red-600" : ""}`}>
                If asked, what would your spouse or children say about you?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='They Are Kind'
                  class='w-4 h-4 gold'
                  {...register("q6", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  They Are Kind
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='They Are Smart'
                  class='w-4 h-4 gold'
                  {...register("q6", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  They Are Smart
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='They Are Caring'
                  class='w-4 h-4 gold'
                  {...register("q6", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  They Are Caring
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='They Are Strong'
                  class='w-4 h-4 gold'
                  {...register("q6", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  They Are Strong
                </label>
              </div>
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q7 Q8 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q7 ? "text-red-600" : ""}`}>Q7:</h1>
              <h1 className={`mb-3 ${errors.q7 ? "text-red-600" : ""}`}>
                When do you have the most energy?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Morning'
                  class='w-4 h-4 gold'
                  {...register("q7", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Morning
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Afternoon'
                  class='w-4 h-4 gold'
                  {...register("q7", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Afternoon
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Night'
                  class='w-4 h-4 gold'
                  {...register("q7", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Night
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Weekends'
                  class='w-4 h-4 gold'
                  {...register("q7", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Weekends
                </label>
              </div>
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q8 ? "text-red-700" : ""}`}>Q8:</h1>
              <h1 className={`mb-3 ${errors.q8 ? "text-red-600" : ""}`}>
                Choose your themesong.
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Eye Of The Tiger - Survivor'
                  class='w-4 h-4 gold'
                  {...register("q8", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Eye Of The Tiger - Survivor
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Will Survive - Gloria Gaynor'
                  class='w-4 h-4 gold'
                  {...register("q8", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Will Survive - Gloria Gaynor
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Lose Yourself - Eminem'
                  class='w-4 h-4 gold'
                  {...register("q8", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Lose Yourself - Eminem
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Danger Zone - Kenny Loggins'
                  class='w-4 h-4 gold'
                  {...register("q8", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Danger Zone - Kenny Loggins
                </label>
              </div>
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q9 Q10 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q9 ? "text-red-600" : ""}`}>Q9:</h1>
              <h1 className={`mb-3 ${errors.q9 ? "text-red-600" : ""}`}>
                Which store would you most likely shop at?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Wal Mart'
                  class='w-4 h-4 gold'
                  {...register("q9", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Wal Mart
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Target'
                  class='w-4 h-4 gold'
                  {...register("q9", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Target
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Local Boutique'
                  class='w-4 h-4 gold'
                  {...register("q9", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Local Boutique
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Shopping Mall'
                  class='w-4 h-4 gold'
                  {...register("q9", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Shopping Mall
                </label>
              </div>
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q10 ? "text-red-700" : ""}`}>
                Q10:
              </h1>
              <h1 className={`mb-3 ${errors.q10 ? "text-red-600" : ""}`}>
                Which social network is your favorite?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Facebook'
                  class='w-4 h-4 gold'
                  {...register("q10", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Facebook
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='X (Twitter)'
                  class='w-4 h-4 gold'
                  {...register("q10", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  X (Twitter)
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Instagram'
                  class='w-4 h-4 gold'
                  {...register("q10", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Instagram
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Tik Tok'
                  class='w-4 h-4 gold'
                  {...register("q10", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Tik Tok
                </label>
              </div>
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q11 Q12 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q11 ? "text-red-600" : ""}`}>
                Q11:
              </h1>
              <h1 className={`mb-3 ${errors.q11 ? "text-red-600" : ""}`}>
                What are you most likely to eat after a stressful day?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Healthy Salad'
                  class='w-4 h-4 gold'
                  {...register("q11", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Healthy Salad
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Wholesome Comfort Meal'
                  class='w-4 h-4 gold'
                  {...register("q11", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Wholesome Comfort Meal
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Something Quick'
                  class='w-4 h-4 gold'
                  {...register("q11", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Something Quick
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Junk Food'
                  class='w-4 h-4 gold'
                  {...register("q11", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Junk Food
                </label>
              </div>
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q12 ? "text-red-700" : ""}`}>
                Q12:
              </h1>
              <h1 className={`mb-3 ${errors.q12 ? "text-red-600" : ""}`}>
                How do you approach meeting new people?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='With Excitement'
                  class='w-4 h-4 gold'
                  {...register("q12", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  With Excitement
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='With Nervousness'
                  class='w-4 h-4 gold'
                  {...register("q12", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  With Nervousness
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Avoidance'
                  class='w-4 h-4 gold'
                  {...register("q12", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Avoidance
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Tik Tok'
                  class='w-4 h-4 gold'
                  {...register("q10", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Tik Tok
                </label>
              </div> */}
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q13 Q14 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q13 ? "text-red-600" : ""}`}>
                Q13:
              </h1>
              <h1 className={`mb-3 ${errors.q13 ? "text-red-600" : ""}`}>
                How do you like to recharge your personal self?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Social Activities With Friends'
                  class='w-4 h-4 gold'
                  {...register("q13", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Social Activities With Friends
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Solitary Hobbies Or Activities'
                  class='w-4 h-4 gold'
                  {...register("q13", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Solitary Hobbies Or Activities
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Relaxying With Family'
                  class='w-4 h-4 gold'
                  {...register("q13", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Relaxying With Family
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Junk Food'
                  class='w-4 h-4 gold'
                  {...register("q11", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Junk Food
                </label>
              </div> */}
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q14 ? "text-red-700" : ""}`}>
                Q14:
              </h1>
              <h1 className={`mb-3 ${errors.q14 ? "text-red-600" : ""}`}>
                Do you enjoy being the center of attention?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Yes I Enjoy It'
                  class='w-4 h-4 gold'
                  {...register("q14", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Yes I Enjoy It
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Tolerate It'
                  class='w-4 h-4 gold'
                  {...register("q14", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Tolerate It
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Dislike It'
                  class='w-4 h-4 gold'
                  {...register("q14", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Dislike It
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Extremely Uncomfortable'
                  class='w-4 h-4 gold'
                  {...register("q14", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Extremely Uncomfortable
                </label>
              </div>
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q15 Q16 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q15 ? "text-red-600" : ""}`}>
                Q15:
              </h1>
              <h1 className={`mb-3 ${errors.q15 ? "text-red-600" : ""}`}>
                How do you handle conflict and difficult situations?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Address It Head On'
                  class='w-4 h-4 gold'
                  {...register("q15", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Address It Head On
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Avoid It'
                  class='w-4 h-4 gold'
                  {...register("q15", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Avoid It
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Spend Time Thinking About Solutions'
                  class='w-4 h-4 gold'
                  {...register("q15", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Spend Time Thinking About Solutions
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Junk Food'
                  class='w-4 h-4 gold'
                  {...register("q11", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Junk Food
                </label>
              </div> */}
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q16 ? "text-red-700" : ""}`}>
                Q16:
              </h1>
              <h1 className={`mb-3 ${errors.q16 ? "text-red-600" : ""}`}>
                What best describes your communication style?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Talkative And Outgoing'
                  class='w-4 h-4 gold'
                  {...register("q16", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Talkative And Outgoing
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Thoughtful And Introspective'
                  class='w-4 h-4 gold'
                  {...register("q16", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Thoughtful And Introspective
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Analytical And Detail Oriented'
                  class='w-4 h-4 gold'
                  {...register("q16", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Analytical And Detail Oriented
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Extremely Uncomfortable'
                  class='w-4 h-4 gold'
                  {...register("q14", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Extremely Uncomfortable
                </label>
              </div> */}
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q17 Q18 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q17 ? "text-red-600" : ""}`}>
                Q17:
              </h1>
              <h1 className={`mb-3 ${errors.q17 ? "text-red-600" : ""}`}>
                What kind of activities do you prefer?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Action Packed'
                  class='w-4 h-4 gold'
                  {...register("q17", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Action Packed
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Calm And Relaxing'
                  class='w-4 h-4 gold'
                  {...register("q17", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Calm And Relaxing
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='A Mix Of Both'
                  class='w-4 h-4 gold'
                  {...register("q17", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  A Mix Of Both
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Junk Food'
                  class='w-4 h-4 gold'
                  {...register("q11", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Junk Food
                </label>
              </div> */}
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q18 ? "text-red-700" : ""}`}>
                Q18:
              </h1>
              <h1 className={`mb-3 ${errors.q18 ? "text-red-600" : ""}`}>
                When working on a project, what do you prefer?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Collaborating With Others'
                  class='w-4 h-4 gold'
                  {...register("q18", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Collaborating With Others
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Working Independently'
                  class='w-4 h-4 gold'
                  {...register("q18", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Working Independently
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='A Mix Of Both'
                  class='w-4 h-4 gold'
                  {...register("q18", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  A Mix Of Both
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Extremely Uncomfortable'
                  class='w-4 h-4 gold'
                  {...register("q14", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Extremely Uncomfortable
                </label>
              </div> */}
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q19 Q20 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q19 ? "text-red-600" : ""}`}>
                Q19:
              </h1>
              <h1 className={`mb-3 ${errors.q19 ? "text-red-600" : ""}`}>
                Which of the following do you enjoy more?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Researching A New Company'
                  class='w-4 h-4 gold'
                  {...register("q19", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Researching A New Company
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Building A Model Car'
                  class='w-4 h-4 gold'
                  {...register("q19", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Building A Model Car
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Meeting Friends At A Museum'
                  class='w-4 h-4 gold'
                  {...register("q19", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Meeting Friends At A Museum
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Going For A Nature Walk'
                  class='w-4 h-4 gold'
                  {...register("q19", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Going For A Nature Walk
                </label>
              </div>
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q20 ? "text-red-700" : ""}`}>
                Q20:
              </h1>
              <h1 className={`mb-3 ${errors.q20 ? "text-red-600" : ""}`}>
                How long have you been interested in blockchain / crypto?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Less Than One Year'
                  class='w-4 h-4 gold'
                  {...register("q20", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Less Than One Year
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='One To Two Years'
                  class='w-4 h-4 gold'
                  {...register("q20", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  One To Two Years
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Two to Five Years'
                  class='w-4 h-4 gold'
                  {...register("q20", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Two to Five Years
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Five Years Plus'
                  class='w-4 h-4 gold'
                  {...register("q20", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Five Years Plus
                </label>
              </div>
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q21 Q22 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q21 ? "text-red-600" : ""}`}>
                Q21:
              </h1>
              <h1 className={`mb-3 ${errors.q21 ? "text-red-600" : ""}`}>
                How did you acquire your 400 NFT?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Received During Initial Airdrop'
                  class='w-4 h-4 gold'
                  {...register("q21", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Received During Initial Airdrop
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Bought On Secondary'
                  class='w-4 h-4 gold'
                  {...register("q21", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Bought On Secondary
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Rarely'
                  class='w-4 h-4 gold'
                  {...register("q21", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Rarely
                </label>
              </div> */}
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Going For A Nature Walk'
                  class='w-4 h-4 gold'
                  {...register("q19", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Going For A Nature Walk
                </label>
              </div> */}
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q22 ? "text-red-700" : ""}`}>
                Q22:
              </h1>
              <h1 className={`mb-3 ${errors.q22 ? "text-red-600" : ""}`}>
                Are you a regular purchaser of NFTs and/or crypto?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Yes Regularly'
                  class='w-4 h-4 gold'
                  {...register("q22", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Yes Regularly
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Somewhat Irregular'
                  class='w-4 h-4 gold'
                  {...register("q22", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Somewhat Irregular
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Rarely'
                  class='w-4 h-4 gold'
                  {...register("q22", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Rarely
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Five Years Plus'
                  class='w-4 h-4 gold'
                  {...register("q20", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Five Years Plus
                </label>
              </div> */}
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q23 Q24 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q23 ? "text-red-600" : ""}`}>
                Q23:
              </h1>
              <h1 className={`mb-3 ${errors.q23 ? "text-red-600" : ""}`}>
                What made you the most interested in continuing with the 400?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='The Mystery'
                  class='w-4 h-4 gold'
                  {...register("q23", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  The Mystery
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Never Sell Gifts'
                  class='w-4 h-4 gold'
                  {...register("q23", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Never Sell Gifts
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Bored With Everything Else'
                  class='w-4 h-4 gold'
                  {...register("q23", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Bored With Everything Else
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Have No Idea'
                  class='w-4 h-4 gold'
                  {...register("q23", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Have No Idea
                </label>
              </div>
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q24 ? "text-red-700" : ""}`}>
                Q24:
              </h1>
              <h1 className={`mb-3 ${errors.q24 ? "text-red-600" : ""}`}>
                How do you usually feel about spending money?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Enjoy Spending'
                  class='w-4 h-4 gold'
                  {...register("q24", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Enjoy Spending
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Try To Be Responsible'
                  class='w-4 h-4 gold'
                  {...register("q24", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Try To Be Responsible
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Feel Guilty When Spending'
                  class='w-4 h-4 gold'
                  {...register("q24", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Feel Guilty When Spending
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Five Years Plus'
                  class='w-4 h-4 gold'
                  {...register("q20", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Five Years Plus
                </label>
              </div> */}
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q25 Q26 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q25 ? "text-red-600" : ""}`}>
                Q25:
              </h1>
              <h1 className={`mb-3 ${errors.q25 ? "text-red-600" : ""}`}>
                What is your risk tolerance with money?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Enjoy Taking Risks'
                  class='w-4 h-4 gold'
                  {...register("q25", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Enjoy Taking Risks
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Take A Few Risks'
                  class='w-4 h-4 gold'
                  {...register("q25", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Take A Few Risks
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Avoid All Risks'
                  class='w-4 h-4 gold'
                  {...register("q25", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Avoid All Risks
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Have No Idea'
                  class='w-4 h-4 gold'
                  {...register("q23", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Have No Idea
                </label>
              </div> */}
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q26 ? "text-red-700" : ""}`}>
                Q26:
              </h1>
              <h1 className={`mb-3 ${errors.q26 ? "text-red-600" : ""}`}>
                Do you consider yourself a religious person?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Yes Religious'
                  class='w-4 h-4 gold'
                  {...register("q26", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Yes Religious
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Somewhat Spiritual'
                  class='w-4 h-4 gold'
                  {...register("q26", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Somewhat Spiritual
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Agnostic'
                  class='w-4 h-4 gold'
                  {...register("q26", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Agnostic
                </label>
              </div>
              {/* <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Five Years Plus'
                  class='w-4 h-4 gold'
                  {...register("q20", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Five Years Plus
                </label>
              </div> */}
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        {/* Q27 Q28 */}
        <div class='px-48 grid lg:grid-cols-2 pt-10 gap-10 px5'>
          <div class='order-1 lg:order-1 flex flex-col '>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q27 ? "text-red-600" : ""}`}>
                Q27:
              </h1>
              <h1 className={`mb-3 ${errors.q27 ? "text-red-600" : ""}`}>
                Do you commonly read books in their entirity?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='All The Time'
                  class='w-4 h-4 gold'
                  {...register("q27", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  All The Time
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Occasionally'
                  class='w-4 h-4 gold'
                  {...register("q27", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Occasionally
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='I Try But It Takes Me A While'
                  class='w-4 h-4 gold'
                  {...register("q27", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  I Try But It Takes Me A While
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Never Read'
                  class='w-4 h-4 gold'
                  {...register("q27", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Never Read
                </label>
              </div>
              {/* {errors.q1 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
          <div class='order-1 lg:order-2'>
            <div class='mb-5'>
              <h1 className={`mb-3 ${errors.q28 ? "text-red-700" : ""}`}>
                Q28:
              </h1>
              <h1 className={`mb-3 ${errors.q28 ? "text-red-600" : ""}`}>
                If you had choose one of the following career paths, which would
                you choose?
              </h1>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Childrens Magician'
                  class='w-4 h-4 gold'
                  {...register("q28", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Childrens Magician
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Traveling Carnival Ride Operator'
                  class='w-4 h-4 gold'
                  {...register("q28", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Traveling Carnival Ride Operator
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Mortician'
                  class='w-4 h-4 gold'
                  {...register("q28", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Mortician
                </label>
              </div>
              <div class='flex items-center mb-4'>
                <input
                  id='country-option-1'
                  type='radio'
                  name='countries'
                  value='Stand Up Comedian'
                  class='w-4 h-4 gold'
                  {...register("q28", { required: true })}
                />
                <label
                  for='country-option-1'
                  class='block ms-2  text-sm font-medium'
                >
                  Stand Up Comedian
                </label>
              </div>
              {/* {errors.q4 && (
                <p class='mt-2 text-sm text-red-600 dark:text-red-500'>
                  Answer is required!
                </p>
              )} */}
            </div>
          </div>
        </div>

        <div className='flex items-center justify-center flex-sm-col mt-20'>
          <button
            type='submit'
            className='border p-2 px-10 mr-4 custom-button mb20 px0'
          >
            {Loading ? (
              <svg
                aria-hidden='true'
                class='w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600'
                viewBox='0 0 100 101'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                  fill='currentColor'
                />
                <path
                  d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                  fill='currentFill'
                />
              </svg>
            ) : (
              "SUBMIT QUESTIONAIRE"
            )}
          </button>
        </div>
      </form>
    </section>
  );
};

export default Survey;
