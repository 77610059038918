import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import "./App.css";
import Header from "./pages/header";
import Text from "./pages/text";
import About from "./pages/about";
import Survey from "./pages/survey";
import Join from "./pages/join";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            exact
            element={<Header />}
          />

          {/* <Route
            path='/about'
            exact
            element={<About />}
          /> */}
          {/* <Route
            path='/text'
            exact
            element={<Text />}
          /> */}
          <Route
            path='/survey'
            exact
            element={<Survey />}
          />

          <Route
            path='/join'
            exact
            element={<Join />}
          />
          <Route
            path='*'
            element={<Navigate to='/' />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
